import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:" d-flex align-center justify-center"},[_c(VCardTitle,{staticStyle:{"margin-left":"6em"},attrs:{"primary-title":""}},[_vm._v(" Novo Registro ")]),_c(VIcon,{staticStyle:{"margin-left":"3em"},attrs:{"size":"30"},on:{"click":function($event){_vm.closeModal(), _vm.resetForm()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c('separator',{attrs:{"label":"Registrar consultor"}}),_c(VForm,{ref:"newRegisterForm"},[_c(VTextField,{staticClass:" mt-7",attrs:{"rules":[_vm.rules.required],"label":"CPF do consultor","dense":"","outlined":""},model:{value:(_vm.vendorCpf),callback:function ($$v) {_vm.vendorCpf=$$v},expression:"vendorCpf"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","disabled":_vm.isLoading},on:{"click":function($event){_vm.closeModal(), _vm.resetForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"info","disabled":_vm.isLoading},on:{"click":_vm.addNewVendor}},[(!_vm.isLoading)?_c('span',[_vm._v("Registrar")]):_c(VProgressCircular,{attrs:{"color":"success","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }