<template>
  <v-card rounded="10">
    <v-card
      elevation="3"
      flat
      rounded="0"
    >
      <v-card-text>
        <v-row class="align-center pt-2">
          <v-col
            class="d-flex justify-center"
            style="padding-left: 150px;"
            cols="10"
          >
            <v-card-title class="pt-0 pb-0 headline">
              {{ localMode === 'edit' ? 'Editar Registro' : 'Metas' }}
            </v-card-title>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="2"
          >
            <v-icon
              size="33"
              class="close-icon"
              @click="closeModal()"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-0">
        <div
          class="d-flex justify-center pt-3 pb-0"
          style="gap: 10px;"
        >
          <v-col
            cols="auto"
            class="pb-0"
          >
            <span
              style="font-size: 15px; font-weight: 600; cursor: pointer"
              class="pa-0 ma-0"
              :class="{ isClicked: localMode === 'edit' }"
              @click="setMode('edit')"
            >
              META DO COLABORADOR
            </span>
            <div
              v-if="localMode === 'edit'"
              class="isClicked"
              style="border-bottom: 2px solid #16B1FF;"
            ></div>
          </v-col>
          <v-col
            cols="auto"
            class="pb-0"
          >
            <span
              style="font-size: 15px; font-weight: 600; cursor: pointer"
              class="pa-0 ma-0 mt-5"
              :class="{ isClicked: localMode === 'history' }"
              @click="setMode('history')"
            >
              HISTÓRICO DE REGISTRO
            </span>
            <div
              v-if="localMode === 'history'"
              class="isClicked"
              style="border-bottom: 2px solid #16B1FF;"
            ></div>
          </v-col>
        </div>
      </v-card-text>
    </v-card>

    <v-card-text class="px-8 pt-4">
      <separator
        class="mb-1"
        :label="localMode === 'edit' ? 'Registro' : 'Informações'"
      ></separator>
    </v-card-text>

    <v-form
      v-if="localMode === 'edit'"
      ref="form"
      @submit.prevent="storeCommission"
    >
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="data.user.name"
            item-text="name"
            item-value="name"
            label="Nome"
            outlined
            disabled
            readonly
            dense
          />
          <v-row>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-autocomplete
                v-model="data.cnh_parameter_goal[0].level"
                :items="levelOptions"
                label="Nivel"
                outlined
                dense
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-text-field
                v-model="data.cnh_parameter_goal[0].goal"
                type="number"
                outlined
                :rules="[rules.zeroed]"
                label="Meta"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-text-field
                v-model="data.user.cpf"
                item-text="cpf"
                item-value="cpf"
                label="CPF"
                outlined
                disabled
                readonly
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pt-0"
              cols="6"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="isoDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    dense
                    outlined
                    label="Período"
                    :append-icon="icons.mdiCalendar"
                    readonly
                    style="width: 100%;"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="isoDate"
                  type="month"
                  no-title
                  scrollable
                  @change="(date) => ($refs.menu.save(date), (isoDate = date))"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              class="pt-0"
              cols="6"
            >
              <div class="d-flex align-start">
                <v-switch
                  v-model="data.cnh_parameter_goal[0].vacation"
                  color="success"
                  inset
                  class="pt-0 mt-0 pl-1"
                />
                <span>Colaborador de férias</span>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="pb-0 pl-0 pr-3 d-flex items-center">
          <div class="d-flex items-center pr-5">
            <v-icon
              size="35"
              class="text-center"
              @mouseover="showTooltip = true"
              @mouseleave="showTooltip = false"
            >
              {{ icons.mdiInformationVariant }}
            </v-icon>
            <transition name="fade">
              <div
                v-if="showTooltip"
                style="font-size: 10px; border-radius: 5px; min-width: 250px"
                class="actionAlert mb-0 px-3 py-2"
                :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
              >
                O período de alteração do registro é do 8º dia do mês até o último dia do mês.
              </div>
            </transition>
          </div>
          <v-spacer></v-spacer>
          <div
            class="d-flex"
            style="gap: 17px"
          >
            <v-btn
              x-large
              color="error"
              :disabled="loading"
              @click="closeModal()"
            >
              Cancelar
            </v-btn>
            <v-btn
              x-large
              color="info"
              :disabled="loading"
              @click="storeCommission()"
            >
              <span v-if="!loading">Atualizar</span>
              <v-progress-circular
                v-else
                color="info"
                indeterminate
              ></v-progress-circular>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card-text>
    </v-form>

    <v-form
      v-else
      ref="form"
    >
      <v-card-text class="px-8 pt-4">
        <v-row>
          <v-text-field
            v-model="referenceFilter"
            class="pl-3"
            style="max-width: 352px"
            label="Buscar por referência"
            item-text="name"
            item-value="name"
            hide-details
            outlined
            dense
            :append-icon="icons.mdiMagnify"
            @click:append="searchByReference(referenceFilter)"
          />
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="pt-6">
          <v-col>
            <v-data-table
              :headers="headers"
              :loading="loading"
              :items="data.cnh_parameter_goal"
              loading-text="Carregando dados..."
              :page.sync="pageOptions.page"
              :items-per-page="pageOptions.itemsPerPage"
              @page-count="$event => (pageCount = $event)"
            >
              <template v-slot:item.reference="{ item }">
                <span>
                  {{ formatReferenceDate(item.reference) }}
                </span>
              </template>
              <template v-slot:item.goal="{ item }">
                <span>
                  {{ item.goal }}
                </span>
              </template>
              <template v-slot:item.level="{ item }">
                <span>
                  {{ item.level }}
                </span>
              </template>
              <template v-slot:item.vacation="{ item }">
                <span
                  v-if="(item.vacation)"
                  class="d-flex items-center justify-center chip-vacations rounded-xl text-sm px-2"
                >Férias</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.created_name="{ item }">
                <span>
                  {{ item.user.created_name }}
                </span>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <span>
                  {{ dateFormat(item.updated_at) }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar,
  mdiClose,
  mdiInformationVariant, mdiMagnify,
} from '@mdi/js'
import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

export default {

  components: { Separator },

  mixins: [messages, formatters],

  props: {
    mode: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    vendorList: {
      type: [Array, Object],
      required: true,
    },
    pageOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isoDate: moment().format('YYYY-MM'),
      vendorCpf: '',
      vendorGoal: '',
      selectedLevel: '',
      referenceFilter: '',
      tableData: this.data.cnh_parameter_goal,
      loading: false,
      headers: [
        {
          text: 'REFERÊNCIA', value: 'reference', align: '', sortable: true, headerClass: 'custom-header',
        },
        {
          text: 'META', value: 'goal', align: 'center', sortable: false, headerClass: 'custom-header',
        },
        {
          text: 'NÍVEL', value: 'level', align: 'center', sortable: false, headerClass: 'custom-header',
        },
        {
          text: 'FÉRIAS', value: 'vacation', align: 'center', sortable: false, headerClass: 'custom-header',
        },
        {
          text: 'REGISTRADO POR', value: 'created_name', align: '', sortable: false, headerClass: 'custom-header',
        },
        {
          text: 'REGISTRADO EM', value: 'updated_at', align: '', sortable: false, headerClass: 'custom-header',
        },
      ],
      vendorIsOnVacation: false,
      showTooltip: false,

      emptyValues: [],
      levelOptions: [
        'JUNIOR',
        'PLENO',
        'SENIOR',
        'ELITE',
      ],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      icons: {
        mdiCalendar, mdiInformationVariant, mdiMagnify, mdiClose,
      },
      localMode: this.mode,
    }
  },

  computed: {
    formattedDate: {
      get() {
        return moment(this.isoDate, 'YYYY-MM').format('MMMM YYYY').toUpperCase()
      },
      set(value) {
        if (!value) {
          this.isoDate = value

          return
        }
        this.isoDate = moment(value, 'MMMM YYYY').format('YYYY-MM')
      },
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },

  watch: {
    mode(newMode) {
      this.localMode = newMode
    },
    localMode(newValue) {
      this.$emit('update:mode', newValue)
    },
  },

  methods: {
    searchByReference(referenceFilter) {
      if (!referenceFilter) {
        this.tableData = this.data.cnh_parameter_goal

        return
      }
      let formattedReferences = []
      const monthNames = moment.months()
      const regexMonthYear = new RegExp(`(${monthNames.join('|')}) ?(\\d{4})?`, 'i')
      const match = referenceFilter.match(regexMonthYear)
      if (match) {
        const month = moment().month(match[1]).format('MM')
        const year = match[2] ? match[2] : null
        if (year) {
          formattedReferences = this.data.cnh_parameter_goal.filter(item => item.reference === `${year}-${month}`)
        } else {
          formattedReferences = this.data.cnh_parameter_goal.filter(item => moment(item.reference, 'YYYY-MM').format('MM') === month)
        }
      }
      if (formattedReferences.length === 0) {
        formattedReferences = this.data.cnh_parameter_goal.filter(item => item.reference === referenceFilter)
      }
      this.tableData = Array.isArray(formattedReferences) ? formattedReferences : [formattedReferences]
    },
    formatReferenceDate(reference) {
      return moment(reference, 'YYYY-MM').format('MMMM YYYY').toUpperCase()
    },
    setMode(mode) {
      this.localMode = mode
    },
    async storeCommission() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        this.showMessage({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios',
        })

        return
      }

      this.loading = true

      const body = {
        goal: Number(this.data.cnh_parameter_goal[0].goal),
        vacation: this.data.cnh_parameter_goal[0].vacation,
        level: this.data.cnh_parameter_goal[0].level,
        reference: moment(this.isoDate).format('YYYY-MM'),
        cnh_parameter_id: this.data.id,
      }

      try {
        await axiosIns.post('/api/v1/integration/cnh_parameter_goal/store/', body)
        this.showMessage({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Dados da comissão atualizados!',
        })

        this.updatedTable()
      } catch (error) {
        this.showMessage({
          icon: 'error',
          title: 'Erro ao atualizar dados!',
          text: error,
        })
      } finally {
        this.closeModal()
        this.loading = false
      }
    },

    clearModal() {
      this.vendorCpf = ''
      this.vendorGoal = ''
      this.vendorIsOnVacation = false

      this.resetForm()
      this.closeModal()
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style scoped>
  .dark-mode {
    background: #1E1E1E4D;
    color: #E7E3FC;
  }
  .light-mode {
    color: #000000;
    background: #eceaed;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .isClicked {
    color: #16B1FF !important;
  }
.close-icon {
  margin-right: 25px;
}

</style>
