import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Adicionar meta global ")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{staticClass:"pb-1 store",attrs:{"items":_vm.regions,"sm":"4","lg":"3","item-value":"id","item-text":"region","label":"Origem","color":"purple","hide-details":"","outlined":"","dense":""},model:{value:(_vm.selectedRegionId),callback:function ($$v) {_vm.selectedRegionId=$$v},expression:"selectedRegionId"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"type":"number","outlined":"","color":"purple","dense":"","label":"Meta","item-text":"name","item-value":"cpf"},model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=$$v},expression:"goal"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.closeModal(), _vm.clearModal()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"success","text":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.addTargetGlobal()}}},[(!_vm.isLoading)?_c('span',[_vm._v("Registrar")]):_c(VProgressCircular,{attrs:{"color":"success","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }