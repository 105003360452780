<template>
  <v-card>
    <v-card-text>
      <v-row class="align-center pt-1">
        <v-col
          class="d-flex justify-center"
          style="padding-left: 100px;"
          cols="10"
        >
          <v-card-title
            class="pt-0 pb-0"
            style="font-weight: 600"
          >
            Filtro
          </v-card-title>
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <v-icon
            size="30"
            class="close-icon pb-2"
            @click="closeModal"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <separator label="Filtrar dados"></separator>
    </v-card-text>
    <v-card-text>
      <v-form
        ref="filterForm"
        class="pt-2"
      >
        <v-container class="pa-0 ma-0">
          <v-row>
            <v-col
              cols="12"
              class="pb-0 mb-0"
            >
              <v-text-field
                v-model="vendorCpf"
                label="CPF"
                class="mt-2"
                style="height: 62px"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="consultor"
                chips
                :items="vendorList"
                small-chips
                clearable
                multiple
                item-text="name"
                item-value="id"
                label="Consultor"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="level"
                small-chips
                clearable
                multiple
                :items="niveis"
                label="Nível"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="meta"
                label="Meta"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedStartDate"
                    label="Data inicial"
                    style="height: 62px"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuStart = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuStart.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedEndDate"
                    label="Data final"
                    style="height: 62px"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuEnd = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuEnd.save(endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div class="d-flex align-center py-2">
            <v-switch
              v-model="vendorVacation"
              color="success"
              inset
              dense
              hide-details=""
              class="mt-0 pl-1"
            />
            <span>Consultor de férias</span>
          </div>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="hasFilters"
        outlined
        color="error"
        :disabled="isLoading"
        @click="clearModal()"
      >
        <div
          class="d-flex align-end justify-center pb-1"
          style="gap: 10px;"
        >
          <v-icon size="22">
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>
          <span style="font-weight: 600; font-size: 14px;">LIMPAR</span>
        </div>
      </v-btn>
      <v-spacer />
      <v-btn
        color="info"
        :disabled="isLoading"
        @click="getFilterData"
      >
        <span v-if="!isLoading">FILTRAR</span>
        <v-progress-circular
          v-else
          color="success"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar,
  mdiClose,
  mdiFilterVariantRemove,
} from '@mdi/js'
import moment from 'moment'
import 'moment/locale/pt-br'
import Separator from '../../../../components/Separator.vue'

export default {
  name: 'ModalFilterTarget',
  components: {
    Separator,
  },
  mixins: [messages, formatters],
  props: {
    vendorList: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
        mdiCalendar,
      },
      isLoading: false,

      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',
      vendorCpf: '',
      consultor: [],
      level: [],
      vendorVacation: false,
      meta: '',
      rules: {
        required: value => !!value || 'Campo obrigatório',
      },
      consultores: [],
      niveis: [
        'JUNIOR',
        'PLENO',
        'SENIOR',
        'ELITE',
      ],
    }
  },
  computed: {
    hasFilters() {
      return this.vendorCpf || this.consultor.length || this.level.length || this.meta || this.startDate || this.endDate || this.vendorVacation
    },
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
  },
  methods: {
    async getFilterData() {
      const {
        vendorCpf,
        consultor,
        level,
        meta,
        startDate,
        endDate,
        vendorVacation,
      } = this

      const body = {
        vendor_name: consultor.length ? consultor.map(id => this.vendorList.find(v => v.id === id)?.name).filter(name => name) : [],
        vendor_cpf: vendorCpf,
        vendor_level: level,
        vendor_goal: meta,
        vendor_vacation: vendorVacation,
        initial_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        final_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      }

      if (this.hasNonEmptyKey(body)) {
        this.isLoading = true

        try {
          const response = await axiosNewInstance.post('/api/v1/integration/cnh_parameter/filter_parameter', body)
          const { data: filteredData } = response.data

          if (filteredData.length === 0) {
            this.showMessage({
              icon: 'warning',
              title: 'Nenhum registro encontrado!',
              text: 'Tente novamente',
            })

            return
          }

          this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })
          this.closeModal()
          this.sendFilteredData(response)
        } catch (error) {
          this.showMessage({
            title: 'Contate a equipe de desenvolvimento, erro ao enviar dados',
            text: error.message,
            icon: 'error',
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.showMessage({ title: 'Campos vazios', text: 'Você deve preencher ao menos um dos campos' })
      }
    },

    sendFilteredData(data) {
      this.$emit('filteredData', data)
      this.$emit('isFilterTrue', this.hasFilters)
    },

    hasNonEmptyKey(obj) {
      return Object.values(obj).some(value => value !== '' && value !== false && value !== 0)
    },

    closeModal() {
      this.$emit('close')
    },

    clearModal() {
      this.vendorCpf = ''
      this.consultor = []
      this.level = []
      this.meta = ''
      this.startDate = ''
      this.endDate = ''
      this.vendorVacation = false
      this.updatedTable()
      this.$emit('isFilterTrue', false)
    },

    resetForm() {
      this.$refs.filterForm.reset()
    },
  },
}
</script>

<style scoped>
.close-icon {
  cursor: pointer;
}
</style>
