<template>
  <v-card>
    <v-card-title>
      Adicionar meta global
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedRegionId"
              :items="regions"
              sm="4"
              lg="3"
              item-value="id"
              item-text="region"
              class="pb-1 store"
              label="Origem"
              color="purple"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="goal"
              type="number"
              outlined
              color="purple"
              dense
              label="Meta"
              item-text="name"
              item-value="cpf"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="error"
          text
          :disabled="isLoading"
          @click="closeModal(), clearModal()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          text
          :disabled="isLoading"
          @click="addTargetGlobal()"
        >
          <span v-if="!isLoading">Registrar</span>

          <v-progress-circular
            v-else
            color="success"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [messages],

  data() {
    return {
      goal: '',
      selectedRegionId: '',
      isLoading: false,

      listReg: [],
      regions: [],
    }
  },

  created() {
    this.listRegions()
  },

  methods: {
    async addTargetGlobal() {
      const body = {
        goal_value: Number(this.goal),
        regions_id: this.selectedRegionId,
      }

      try {
        this.isLoading = true
        await axiosIns.post('api/v1/integration/general_goal/store', body)

        this.showMessage({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Meta adicionada com sucesso!',
          timer: 2500,
        })
        this.isLoading = false
        this.clearModal()
      } catch (error) {
        this.showMessage({
          icon: 'error',
          title: 'Erro ao cadastrar meta!',
          text: error,
          timer: 2500,
        })
      } finally {
        this.closeModal()
      }
    },

    async listRegions() {
      try {
        const response = await axiosIns.get('api/v1/records/region/index')
        this.regions = response.data?.data
      } catch (error) {
        console.error(error)
      }
    },

    clearModal() {
      this.goal = ''
      this.selectedRegionId = ''
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
